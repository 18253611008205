
import Dialog from '../ui/Dialog'
import ToastManager from '~/fc/ToastManager'
import Converter from "~/fc/Converter/Converter"
import BulkConverter from '~/fc/Converter/BulkConverter'
import { copyToClipBoard } from '~/fc/helpers'
import { ExternalLinkIcon } from "vue-feather-icons";

export default {
    name: 'JobBuilderDialog',
    components: {
        Dialog,
        ExternalLinkIcon,
        IconCode: () => import(/* webpackChunkName: "IconCode" */ '../ui/icon/IconCode'),
    },
    data: () => ({
        loading: false,
        file: null,
        tasks: null,
        copyButtonText: 'Copy'
    }),
    computed: {
        info() { return this.$store.state.info },
        target() { return this.info.target.ext },
        list () { return this.$store.state.list },
        isMobile() { return this.$store.state.store.isMobile },
        merging () { return this.$store.state.advancedSetting.merging },
        toastManager() { return new ToastManager(this) },
        baseUrl() { return process.env.BASE_URL },
        slug() {
            if (this.merging) return this.merging;
            if (this.info.slug) return this.info.slug;
            return this.info.source.ext + "-to-" + this.info.target.ext;
        },
    },
    created() {
        this.copyButtonText = this.$t('copy')
    },
    methods: {
        open(props) {
            this.file = props ? props.file : 'bulk'
            this.persistJob()
            this.openDialog()
        },
        async persistJob() {
            this.tasks = this.file === 'bulk'
                ? await new BulkConverter(this).process(() => {}, true)
                : new Converter(this).setFile(this.file).setVariables(true)
            window.localStorage.setItem('job_builder_job', JSON.stringify({'tasks': this.tasks}));
        },
        openDialog() {
            this.$refs.JobBuilderDialog.open()
        },
        copyTasksToClipboard() {
            const code = document.getElementById('JobBuilder')?.contentWindow?.document.querySelector('code')?.innerText || JSON.stringify({'tasks': this.tasks})
            copyToClipBoard(code)
            this.copyButtonText = this.$t('copied')
            setTimeout(() => this.copyButtonText = this.$t('copy'), 1000)
        }
    }
}
