
import { XIcon } from "vue-feather-icons";
export default {
  name: "DialogModal",
  components: { XIcon },
  props: {
    centered: {
      default: false,
      required: false,
    },
    size: {
      default: "",
      required: false,
    },
    url: {
      default: false,
      required: false,
    },
    strict: {
      default: false,
      required: false,
    },
    topFix: {
      default: false,
      required: false,
    },
    allowClose: {
      default: true,
      required: false,
    },
    // when we just want both the header and footer to be hidden
    contentOnly: {
      default: false,
      type: Boolean
    },
    // when we just want the header to be hidden
    noHeader: {
      default: false,
      type: Boolean
    },
    fullbox: {
      default: false,
      required: false,
    },
  },
  data: () => ({ show: false }),
  watch: {
    show(val) {
      val
      ? document.getElementById("navbar-container").classList.add("has-dialog")
      : document.getElementById("navbar-container").classList.remove("has-dialog")
    },
  },
  methods: {
    open() {
      document.getElementById("navbar-container").classList.add("has-dialog");
      /**
       * We are executing the following block of code inside
       * a 250ms timeout. This puts it under a separate event 
       * queue and will result in a arbitrary delay which would
       * be enough for the style changes of .has-dialog to be 
       * applied. 
       * Without this we would see a flickering of the navigation
       * bar when we open the dialog.
       */
      setTimeout(() => {
        this.show = true;
        if (!this.fullbox) document.body.classList.add("scroll--off");
      }, 250);
    },
    close() {
      document.body.classList.remove("scroll--off");
      this.$emit("close", "modalClose");
      this.show = false;
    },
    cancel() {
      document.body.classList.remove("scroll--off");
      this.$emit("cancel");
      this.show = false;
    },
  },
};
